/* You can add global styles to this file, and also import other style files */
// @import "~bootstrap/dist/css/bootstrap.min.css";

// :root {
// --primary: #3F51B5;
// --primaryBackdrop: #0021477a;
// --primaryBackground: #5C6BC0;
// --primaryTextColor: #ffffff;
// --accent: #FFC107;
// --accentBackground: #ffcc337a;
// --accentTextColor: #002147;
// --secondary: #515151;
// --secondaryBackground: #bfbdbd;
// --disabled: #bfbdbd;
// --disabledBackground: #bfbdbd;

// --defaultBackdrop: #999999;
// --defaultBackground: #f1f1f1;
// --error: #ce0c0c;
// --warn: #ff9666;
// --info: #3F51B5;
// --default: #515151;
// --success: #5b7a21;
// }

@mixin style-base($color) {
  color: $color;
  background-color: rgba($color, 0.5);
}

@mixin style-column-cell($color) {
  color: $color;
  background-color: rgba($color, 0.5);
  padding: 0px 5px 0px 5px;
  border-radius: 3px;
}

@mixin style-button($color) {
  border: 1px solid var($color);
  color: var($color);
  background: white;
  height: 30px;
  line-height: initial;
  outline: none;

  // opacity: 0.8;

  //   border: 1px solid var(--primary);
  //   color: var(--primary);
  //   background: white;
  //   outline: none;
  //   height: 30px;
  //   line-height: initial;

  &:focus,
  &:hover,
  &.filled {
    color: white;
    background-color: var($color);
    // border: 1px solid var($color);
    // color: $color;
    // background-color: white;
    opacity: 1;
    // outline: none;
  }
}

@mixin subtle() {
  .show-on-hover {
    display: none !important;
  }

  .hide-on-hover {
    display: block !important;
  }

  i,
  mat-icon,
  & > i,
  & > mat-icon {
    opacity: 0.3;
    cursor: pointer;

    &.not-subtle {
      opacity: 1;
    }
  }

  .not-subtle {
    & > i,
    i,
    & > mat-icon,
    mat-icon {
      opacity: 1;
    }
  }

  &:hover {
    i,
    mat-icon,
    & > i,
    & > mat-icon {
      opacity: 1;
    }

    .show-on-hover {
      display: block !important;
    }

    .hide-on-hover {
      display: none !important;
    }
  }
}

@mixin style-toggle-button($color) {
  border: 1px solid $color;
  color: $color;
  background-color: white;

  &:hover,
  &.filled {
    border: 1px solid $color;
    background-color: $color;
    color: white;
  }
}

@mixin style-filled-button($color) {
  border: 1px solid $color;
  background-color: $color;
  color: white;
}

@mixin style-unfilled-button($color) {
  border: 1px solid $color;
  color: $color;
  background-color: white;
}

@mixin styled(
  $primary: #3f51b5,
  $accent: #ffc107,
  $default: #515151,
  $success: #5b7a21,
  $warn: #ff9666,
  $error: #ce0c0c,
  $secondary: #515151
) {
  &.text {
    color: $primary;
  }

  .disabled {
    color: rgba($default, 0.7);
  }

  .primary {
    @include style-base($primary);
  }

  button {
    &.primary {
      @include style-button($primary);
    }

    &.accent {
      @include style-button($accent);
    }

    &.default {
      @include style-button($default);
    }

    &.success {
      @include style-button($success);
    }

    &.warn {
      @include style-button($warn);
    }

    &.error {
      @include style-button($error);
    }

    &.sage {
      @include style-button($primary);
    }

    &.peacock {
      @include style-button($primary);
    }

    &.basil {
      @include style-button($primary);
    }

    &.tangerine {
      @include style-button($primary);
    }

    &.tomato {
      @include style-button($primary);
    }

    &.banana {
      @include style-button($primary);
    }

    &.blueberry {
      @include style-button($primary);
    }

    &.lavender {
      @include style-button($primary);
    }

    &.flamingo {
      @include style-button($primary);
    }

    &.grape {
      @include style-button($primary);
    }

    &.graphite {
      @include style-button($primary);
    }
  }

  .accent {
    @include style-base($accent);
  }

  .default {
    @include style-base($default);
  }

  .success {
    @include style-base($success);
  }

  .warn {
    @include style-base($warn);
  }

  .error {
    @include style-base($error);
  }

  .tangerine {
    @include style-base($primary);
  }

  .tomato {
    @include style-base($primary);
  }

  .flamingo {
    @include style-base($primary);
  }

  .banana {
    @include style-base($primary);
  }

  .sage {
    @include style-base($primary);
  }

  .basil {
    @include style-base($primary);
  }

  .peacock {
    @include style-base($primary);
  }

  .blueberry {
    @include style-base($primary);
  }

  .lavender {
    @include style-base($primary);
  }

  .grape {
    @include style-base($primary);
  }

  .graphite {
    @include style-base($primary);
  }

  .active-step {
    @include style-unfilled-button($primary);
  }

  .inactive-step {
    @include style-unfilled-button($secondary);
  }

  &.not-started {
    @include style-toggle-button($secondary);
  }

  &.in-progress {
    @include style-filled-button($primary);
  }

  &.active .index {
    @include style-unfilled-button($primary);
  }

  &.done {
    @include style-toggle-button($primary);
  }

  &.active-step .index {
    @include style-filled-button($primary);
  }

  &.inactive-step .index {
    @include style-filled-button($secondary);
  }
}

.tomato {
  & > .values {
    @include style-column-cell(#ff6347);
  }
}

.peacock {
  & > .values {
    @include style-column-cell(#33a1c9);
  }
}

.blueberry {
  & > .values {
    @include style-column-cell(#4f86f7);
  }
}

.graphite {
  & > .values {
    @include style-column-cell(#4b4e53);
  }
}

.tangerine {
  & > .values {
    @include style-column-cell(#f28500);
  }
}

.flamingo {
  & > .values {
    @include style-column-cell(#fc8eac);
  }
}

.banana {
  & > .values {
    @include style-column-cell(#ffe135);
  }
}

.sage {
  & > .values {
    @include style-column-cell(#36352f);
  }
}

.basil {
  & > .values {
    @include style-column-cell(#579229);
  }
}

.lavender {
  & > .values {
    @include style-column-cell(#e6e6fa);
  }
}

.grape {
  & > .values {
    @include style-column-cell(#6f2da8);
  }
}

.styled {
  &.default {
    @include styled();
  }

  &.active-step {
    @include styled($primary: #ea2330);
  }

  &.inactive-step {
    @include styled();
  }

  &.tomato {
    @include styled($primary: #ff6347);
  }

  &.tangerine {
    @include styled($primary: #f28500);
  }

  &.flamingo {
    @include styled($primary: #fc8eac);
  }

  &.banana {
    @include styled($primary: #ffe135);
  }

  &.sage {
    @include styled($primary: #b2ac88);
  }

  &.basil {
    @include styled($primary: #579229);
  }

  &.peacock {
    @include styled($primary: #33a1c9);
  }

  &.blueberry {
    @include styled($primary: #4f86f7);
  }

  &.lavender {
    @include styled($primary: #e6e6fa);
  }

  &.grape {
    @include styled($primary: #6f2da8);
  }

  &.graphite {
    @include styled($primary: #251607);
  }
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.dark-backdrop {
    background: #000;
    opacity: 0.85 !important;
  }
}

.popup {
  &.dropdown {
    display: block;
    background-color: #ffff;
    position: absolute;
    transition: all 0.5s ease;
    box-shadow: 0 1px 3px 0px;
    border-radius: 3px;
    z-index: 10000;
    max-height: 350px;
    padding: 0.5em;
    min-width: max-content;
    width: 100%;

    &.up {
      bottom: 32px;
    }

    &.down {
      top: 35px;
    }
  }

  &.full {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.bottom-sheet {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 101;
    background-color: #fff;
    padding: 5px;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.22),
      0px 14px 56px rgba(0, 0, 0, 0.25);
    // transform: translate(0,100%);
    // transition: all 450ms cubic-
  }
}

/* width */
::-webkit-scrollbar {
  // width: 10px;
  // height: 10px;
}

$padding: 5px;
// $font: Poppins !important;

@mixin middle() {
  margin-top: auto;
  margin-bottom: auto;
}

@mixin row-of-elements() {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

@mixin inline-control() {
  // height: 30px;
  line-height: initial;
  margin-left: 5px;
  // box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
  margin-top: auto;
  margin-bottom: auto;
  height: inherit;
  border-radius: 5px;
}

@mixin fill-remaining-space() {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

@mixin padded-horizontal-container() {
  padding-left: $padding;
  padding-right: $padding;
}

@mixin padded-vertical-container() {
  padding-top: $padding;
  padding-bottom: $padding;
}

@mixin badge() {
  color: var(--primary);
  min-width: 20px;
  text-align: center;
  width: fit-content;
  padding-left: 2px;
  padding-right: 2px;
  height: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid var(--primary);
}

/* Track */
// ::-webkit-scrollbar-track {
//   background: #f1f1f1 !important;
//   border-radius: 15px !important;
// }

/* Handle */
::-webkit-scrollbar-thumb {
  // background-color: #c1c1c1;
  // border-radius: 9px;
  // border: 2px solid transparent;
  // background-clip: content-box;
}

.www-container {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
}

.app-container {
  height: 100vh;
  // width: calc(100vw - 60px);
  width: 100vw;
  overflow-y: auto;
  // margin-left: 60px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;

    &:hover {
      background: #555;
    }
  }
}

.nav-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
}

.header-container {
  position: fixed;
  padding-top: 3px;
  padding-bottom: 3px;
  // width: calc(100vw - 60px);
  width: 100vw;
  z-index: 1000;
  // box-shadow: 0 0px 0px 0px #00000033, 0 0px 0px 0 #00000024, 0 1px 4px 0 #0000001f;
}

.page-container {
  &.with-header {
    margin-top: 40px !important;
  }
}

.search-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--primary);
  padding: 10px;
  gap: 0.5%;
  flex-wrap: wrap;
  // min-height: 44px;
}

.page {
  .context-container {
    height: 30px;
    padding-top: 1em !important;
  }

  .search-bar {
    padding: 5px 0;
  }

  .content {
    height: calc(100vh - 100px);
  }
}

.logo-container {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 64px;
  padding: 10px 5px;
}

// .h1,
// .h2,
// .h3,
// .h4,
// .h5,
// .h6,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-family: inherit;
//   font-weight: 500;
//   line-height: 1.1;
//   color: inherit;
// }

// .h4,
// .h5,
// .h6,
// h4,
// h5,
// h6 {
//   margin-top: 10px;
//   margin-bottom: 10px;
// }

// .h4,
// h4 {
//   font-size: medium;
// }

// .h5,
// h5 {
//   font-size: 14px;
// }

label {
  color: var(--disabled);
  padding-right: 5px;
}

button:focus {
  outline: none;
}

/* oa-theme:start */

.primary {
  color: var(--primary);
  opacity: 1 !important;

  // background-color: var(--primaryBackground);
  &.filled {
    color: #fff;
    background-color: var(--primary);
  }

  &.subtle {
    background-color: var(--primaryBackground);
  }
}

.accent {
  color: var(--accent);
  opacity: 1 !important;

  &.filled {
    color: #fff;
    background-color: var(--accent);
  }

  &.subtle {
    background-color: var(--accentBackground);
  }
}

.default {
  color: var(--default);
  opacity: 1 !important;

  &.filled {
    color: #fff;
    background-color: var(--default);
  }

  &.subtle {
    background-color: #ffff;
  }
}

.disabled {
  color: var(--disabled);
  // background-color: var(--disabledBackground);

  &.filled {
    color: #fff;
    background-color: var(--disabled);
  }

  &.subtle {
    background-color: var(--disabledBackground);
  }
}

button {
  &.active {
    background: var(--primary);
    color: white;
    font-weight: bold;
  }

  &.primary {
    @include style-button(--primary);
  }

  &.accent {
    @include style-button(--accent);
  }

  &.default {
    @include style-button(--default);
  }

  &.success {
    @include style-button(--success);
  }

  &.warn {
    @include style-button(--warn);
  }

  &.error {
    @include style-button(--error);
  }

  // &.primary {

  //   border: 1px solid var(--primary);
  //   color: var(--primary);
  //   background: white;
  //   outline: none;
  //   height: 30px;
  //   line-height: initial;
  // }
}

.stacked {
  display: flex;
  flex-direction: column;

  & > .footer {
    padding-top: 3px;
  }
}

.list-item {
  margin-bottom: 3px;
}

.list {
  & > *,
  & > .list-item {
    padding-left: 10px;
    padding-right: 5px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ffff;
    margin-bottom: 10px;

    & > * {
      margin-left: 10px;
    }

    & > .footer {
      padding-top: 3px;
    }

    &:last-of-type {
      padding-bottom: none;
      border-bottom: none;
      margin-bottom: none;
    }
  }
}

.main {
  // min-height: 70vh;
  // height: calc(100vh - 100px);
  // margin-top: 15px;
  // overflow-y: scroll;
  // margin-bottom: 25px;
  // border-radius: 5px;
  // background: #ffffff;
  // padding: 0px 5px;
  // padding-bottom: 15px;
  // padding: 5px;

  & > .mat-elevation-z2,
  &.mat-elevation-z2 {
    background: #fff;
  }

  & > .header {
    // border-bottom: 1px solid #ffff;
    // margin-bottom: 15px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;

    button {
      height: 30px;
    }
  }

  & > .filters {
    margin-bottom: 10px;
  }

  & > mat-card {
    margin: 5px 0;
    min-height: 40px;

    &:first-child {
      margin-top: 0px;
    }
  }
}

.body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

.section {
  // margin-top: 15px;

  &.card {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    padding: 5px;
  }

  &.bordered {
    padding: 5px;
    border: 1px solid var(--disabledBackground);
    margin: 10px 0px;
    border-radius: 5px;
  }

  & > .body {
    margin-top: 5px;
    height: 100%;
    min-height: 100px;

    &.highlighted {
      // border: dashed 1px var(--secondaryBackground);
      border-radius: 5px;
      // padding: 10px;
      background: var(--secondaryBackground);
      padding: 10px;
      border-radius: 5px;
    }
  }

  & > .header {
    border-bottom: 1px solid #ffff;
    margin: 10px 0 0 0;
    padding-bottom: 5px;

    & > * {
      @include middle();
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }

      &.with-background {
        border-bottom: none;
        background: #ffff;
        padding: 8px;
        border-radius: 4px;
        margin-bottom: 10px;
      }

      & > h5 {
        color: var(--default);
      }

      & > i {
        height: 19px;
        width: 19px;
      }

      & > select,
      & > button,
      button {
        @include inline-control();
      }

      & > .oa-input {
        width: auto;
        min-width: 150px;
      }
    }

    &.preview,
    .preview {
      label {
        font-size: smaller;
        font-weight: 100;
        margin-right: 10px;
      }
    }

    & > .footer {
      border-top: 1px solid #ffff;
      margin-top: 5px;
      padding-top: 5px;

      & > * {
        @include middle();
      }
    }
  }

  .collapsed-card {
    & > .header {
      margin-bottom: 5px;
      padding-bottom: 5px;

      & > * {
        @include middle();
      }

      & > h5 {
        color: var(--default);
      }

      & > i {
        margin-right: 5px;
        height: 19px;
        width: 19px;
      }

      & > select,
      & > button,
      button {
        @include inline-control();
      }

      & > .oa-input {
        width: auto;
        min-width: 150px;
      }
    }

    & > .preview {
      border-top: 1px solid #ffff;
      padding-top: 15px;
      margin-top: 15px;

      & > .heading {
        font-weight: bold;
        margin: 10px 0px;
      }
    }
  }
}

.sections {
  padding-left: 5px;
  padding-right: 5px;

  & > .section {
    margin-top: 15px;
  }

  & > .close {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }

  & > .divider {
    margin-top: 5px;
    display: flex;
    align-items: center;
    text-align: center;

    &::before {
      // &::after {
      content: "";
      flex: 1;
      // border-bottom:1px dashed var(--disabled)
    }

    &:not(:empty)::before {
      margin-right: 0.25em;
    }

    &:not(:empty)::after {
      margin-left: 0.25em;
    }

    & > label {
      margin-bottom: auto;
    }
  }
}

.tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;

  & > * {
    float: left;
    background: var(--accentBackground);
    border-radius: 5px 0 0 5px;
    color: var(--accent);
    // display: inline-block;
    // height: 26px;
    // line-height: 26px;
    height: 20px;
    line-height: 20px;
    font-size: smaller;
    padding: 0 15px 0 5px;
    position: relative;
    margin-left: 5px;
    text-decoration: none;
    // -webkit-transition: color 0.2s;

    // &::before {
    //   background: #fff;
    //   border-radius: 10px;
    //   box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
    //   content: '';
    //   height: 6px;
    //   left: 10px;
    //   position: absolute;
    //   width: 6px;
    //   top: 10px;
    // }

    &::after {
      background: #fff;
      border-bottom: 10px solid transparent;
      border-left: 10px solid var(--accentBackground);
      border-top: 10px solid transparent;
      content: "";
      position: absolute;
      right: 0;
      top: 0;
    }

    &:hover {
      background-color: var(--accent);
      color: white;
    }

    &:hover::after {
      border-left-color: var(--accent);
    }
  }
}

.main {
  & > .header {
    @include row-of-elements();

    & > * {
      @include middle();
    }

    & > h5 {
      color: var(--default);
    }

    & > select,
    & > button,
    button {
      @include inline-control();
    }
  }
}

.action-button button {
  @include inline-control();
}

.hidden {
  display: none !important;
}

input.hidden {
  display: unset !important;
  width: 0;
  height: 0;
  border: none;
}

.hide {
  opacity: 0;
}

.main > .header {
  padding: 8px 0 12px 0;
}

.on-hover {
  opacity: 0;

  &:hover {
    opacity: 1;
  }
}

.clickable {
  color: var(--accent);
  cursor: pointer;
}

.scrollable {
  height: inherit;
  overflow: hidden;

  & > * {
    height: inherit;
    overflow-y: hidden;
    padding-left: 10px;
    padding-right: 10px;
  }

  & > *:hover {
    overflow-y: scroll;
    padding-right: 0px;
  }

  // &>*::-webkit-scrollbar {
  //   position: absolute
  // }
}

.hoverable {
  & > .show {
    display: block !important;
  }

  & > .hide {
    display: none !important;
  }
}

.draggable {
  .handle {
    cursor: grab;
  }

  &.selected {
    border: 1px dashed var(--primary);
    z-index: 2;
  }
}

.drop-zone {
  min-height: 25px;
  width: 100%;

  &.overlay {
    position: relative;
    height: 100%;
    z-index: 1;
    opacity: 0.5;
    background: #ffffff;
  }

  .ready-for-drop,
  &.ready-for-drop {
    border: 1px dashed var(--primary);
  }

  &.uploading {
    border: 1px dashed var(--accent);
  }
}

mat-icon.subtle,
i.subtle {
  opacity: 0.3;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

// .cdk-overlay-pane {
//   max-width: 100vw !important;
// }

.subtle {
  @include subtle();
}

mat-icon,
i {
  &.sm {
    height: 20px;
  }

  &.active {
    color: var(--primary);
    opacity: 0.7;
  }

  &.label {
    margin-right: 5px;
    opacity: 0.5;
  }
}

.footer,
.header,
.action-bar,
.controls-row,
.mat-dialog-title.controls-row {
  @include row-of-elements();

  &:first-child {
    margin-left: 0px;
  }

  &:last-child {
    margin-left: 0px;
  }

  .input-group {
    max-width: 200px;
    padding-top: 5px;

    &.wide {
      max-width: 350px;
      padding-top: 5px;
    }
  }

  & > .oa-input,
  & > button {
    @include inline-control();
    height: 40px;
  }
}

.action-bar {
  justify-content: flex-end;
}

.footer {
  & > .oa-input,
  & > button {
    @include inline-control();
    height: 40px;
  }
}

.spacer {
  @include fill-remaining-space();
}

.header {
  .switch {
    margin: auto;
    padding-left: 5px;
  }

  & > mat-icon {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.w-p,
.flex-1-1-1,
.flex-1-1-2,
.flex-1-2-1,
.flex-1-3-1,
.flex-3-1-1,
.flex-1-3-2,
.flex-3-1-3,
.flex-2-1-2,
.flex-1-7-2,
.flex-1-1,
.flex-1-2,
.flex-2-1,
.flex-1-3,
.flex-3-1,
.flex-3-2,
.flex-1-4,
.flex-4-1,
.flex-2-8,
.flex-8-2,
.flex-1-6,
.flex-6-1,
.flex-6-4,
.flex-1-9,
.flex-9-1,
.flex-7-3,
.flex-row {
  @include row-of-elements();
}

.flex-row {
  flex-wrap: wrap;

  & > .wrapper {
    padding: 5px;
  }

  &.centered {
    align-items: center;
    justify-content: center;

    &.flex-1-1-1 {
      min-width: 33%;
    }

    &.flex-1-2-1 {
      min-width: 50%;
    }

    &.flex-1-3-1 {
      min-width: 60%;
    }

    &.flex-1-4-1 {
      min-width: 60%;
    }
  }

  & > * {
    padding-right: 5px;
    margin-top: auto;
    margin-bottom: auto;
  }

  &.six > * {
    flex-basis: 16%;
  }

  &.five > * {
    flex-basis: 20%;
  }

  &.four > * {
    flex-basis: 25%;
  }

  &.three > * {
    flex-basis: 33%;
  }

  &.two > * {
    flex-basis: 50%;
  }

  &.one > * {
    flex-basis: 100%;
  }

  & > .wrapper {
    padding: 5px;
  }

  & > .left {
    flex-basis: 20%;
    // flex-basis: 200px;
    padding: 5px;
    order: 1;
  }

  & > .middle {
    flex-basis: 30%;
    padding: 5px;
    order: 2;
  }

  & > .right {
    flex-basis: 20%;
    /* flex: 1; */
    padding: 5px;
    order: 3;
  }

  & > .col {
    flex: 1;
    padding: 5px;
  }
}

.w-p {
  & > :first-child {
    flex-basis: 70%;
  }

  & > :last-child {
    flex-basis: 30%;
  }
}

.flex-3-1-1 {
  & > :first-child {
    flex-basis: 60%;
  }

  & > :nth-child(2) {
    flex-basis: 20%;
  }

  & > :last-child {
    flex-basis: 20%;
  }
}

.flex-1-3-1 {
  & > :first-child {
    flex-basis: 20%;
  }

  & > :nth-child(2) {
    flex-basis: 60%;
  }

  & > :last-child {
    flex-basis: 20%;
  }
}

.flex-1-2-1 {
  & > :first-child {
    flex-basis: 25%;
  }

  & > :nth-child(2) {
    flex-basis: 50%;
  }

  & > :last-child {
    flex-basis: 25%;
  }
}

.flex-2-1-2 {
  & > :first-child {
    flex-basis: 40%;
  }

  & > :nth-child(2) {
    flex-basis: 20%;
  }

  & > :last-child {
    flex-basis: 40%;
  }
}

.flex-3-1-3 {
  & > :first-child {
    flex-basis: 45%;
    width: 45% !important;
  }

  & > :nth-child(2) {
    flex-basis: 10%;
  }

  & > :last-child {
    flex-basis: 45%;
    width: 45% !important;
  }
}

.flex-1-1-1 > * {
  flex-basis: 33%;
}

.flex-1-1-2 {
  & > :first-child {
    flex-basis: 25%;
  }

  & > :nth-child(2) {
    flex-basis: 25%;
  }

  & > :last-child {
    flex-basis: 50%;
  }
}

.flex-1-7-2 {
  & > :first-child {
    flex-basis: 10%;
  }

  & > :nth-child(2) {
    flex-basis: 70%;
  }

  & > :last-child {
    flex-basis: 10%;
  }
}

.flex-1-1 > :first-child {
  flex-basis: 50%;
}

.flex-1-2 > :first-child {
  flex-basis: 33%;
}

.flex-2-1 > :first-child {
  flex-basis: 60%;
  width: 60% !important;
}

.flex-1-3 > :first-child {
  flex-basis: 25%;
}

.flex-3-1 > :first-child {
  flex-basis: 75%;
  width: 75% !important;
}

.flex-1-4 > :first-child {
  flex-basis: 20%;
}

.flex-4-1 > :first-child {
  flex-basis: 80%;
}

.flex-2-8 > :first-child {
  flex-basis: 20%;
}

.flex-8-2 > :first-child {
  flex-basis: 80%;
}

.flex-7-3 > :first-child {
  flex-basis: 70%;
}

.flex-1-9 > :first-child {
  flex-basis: 10%;
}

.flex-9-1 > :first-child {
  flex-basis: 90%;
}

.flex-1-6 > :first-child {
  flex-basis: 16%;
}

.flex-2-1 > :first-child,
.flex-1-2 > :first-child,
.flex-1-3 > :first-child,
.flex-3-1 > :first-child,
.flex-1-4 > :first-child,
.flex-1-6 > :first-child,
.flex-4-1 > :first-child,
.flex-1-9 > :first-child,
.flex-2-8 > :first-child,
.flex-8-2 > :first-child,
.flex-7-3 > :first-child,
.flex-9-1 > :first-child {
  order: 1;
  margin-right: 5px;
}

.flex-2-1 > :last-child,
.flex-1-2 > :last-child,
.flex-1-3 > :last-child,
.flex-3-1 > :last-child,
.flex-1-4 > :last-child,
.flex-1-6 > :last-child,
.flex-4-1 > :last-child,
.flex-1-9 > :last-child,
.flex-2-8 > :last-child,
.flex-8-2 > :last-child,
.flex-7-3 > :last-child,
.flex-9-1 > :last-child {
  order: 2;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.flex-row {
  @include row-of-elements();
  flex-wrap: wrap;

  & > .wrapper {
    padding: 5px;
  }

  & > mat-form-field > .mat-form-field-wrapper {
    margin-left: 5px;
    margin-right: 5px;
  }

  & > .form-group {
    padding: 0 5px;
  }

  & > .pill-box {
    margin: 5px;
  }
}

.inline {
  position: relative;
  display: inline-block;

  .popup {
    display: block;
    position: absolute;
    transition: all 0.5s ease;
    border-radius: 5px;
    z-index: 999;
    padding: 10px;
    box-shadow: 0 1px 3px 0px;
    // background-color: #ffff;
    background-color: white;
    color: initial;

    min-height: 100px;
    max-width: 400px;
    min-width: 350px;
    border-radius: 2px;
    overflow-y: auto;

    &.right-down {
      left: 0;
      top: 30px;
    }

    &.right-up {
      left: 0;
      bottom: 30px;
    }

    &.left-down {
      right: 0;
      top: 30px;
    }

    &.left-up {
      right: 0;
      bottom: 30px;
    }
  }

  button {
    @include inline-control();
  }
}

.hr,
hr {
  &.divider {
    border: dashed 1px var(--disabledBackground);
  }

  &.vertical-divider {
    border: none;
    border-left: dashed 1px var(--disabledBackground);
  }

  &.gap {
    border: none;
    margin-top: 10px;
  }
}

.sub-text {
  white-space: pre-line;
  font-size: small;
  color: var(--disabled);
  font-weight: 100;

  & > b {
    margin-left: 3px;
    margin-right: 3px;
  }

  &.sm,
  & > small {
    font-size: xx-small;
  }

  & > label {
    font-weight: 400;
    width: 100%;
  }

  & > span,
  & > p {
    line-height: 0;
    color: var(--secondary);
  }
}

small.sub-text {
  font-size: xx-small;
}

label.sub-text {
  font-weight: 400;
  width: 100%;
}

span.sub-text {
  line-height: 0;
  color: var(--secondary);
}

span {
  &.code {
    color: var(--primary);
    text-transform: uppercase;
  }

  &.value {
    color: var(--secondary);
    // text-transform: uppercase;

    &.error {
      font-weight: bold;
      color: var(--error);
    }

    &.success {
      font-weight: bold;
      color: var(--success);
    }

    &.warn {
      font-weight: bold;
      color: var(--warn);
    }

    &.disabled {
      font-style: italic;
      color: var(--disabled);
    }

    &.placeholder {
      font-weight: 100;
      color: var(--disabled);
      // font-style: italic;
    }
  }

  &.unit,
  &.operator {
    color: var(--disabled);
  }

  &.stat {
    @include badge();
    margin-right: 5px;
    display: initial;

    &.primary {
      border-color: var(--primary);
    }

    &.accent {
      border-color: var(--accent);
    }

    &.disabled {
      border-color: var(--disabled);
    }
  }
}

span.code,
div.code,
input.code,
.input.code {
  color: var(--primary);
  text-transform: uppercase;
}

.oa-field {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  width: 100%;

  & > .value {
    font-size: small;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
  }
}

.controls-row,
.header {
  & > .oa-field {
    width: initial;
    margin-bottom: initial;
    margin-left: 15px;

    & > label {
      margin-top: 2px;
    }
  }
}

.oa-input-group {
  min-width: 190px;

  &.sm {
    min-width: 100px;
  }

  &.md {
    width: 200px;
  }

  &.lg {
    min-width: 400px;
  }
}

.oa-label {
  @include row-of-elements();

  & > * {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.capitalize {
  text-transform: capitalize;
}

.oa-input {
  height: 40px;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  border: 1px solid var(--disabledBackground);
  border-radius: 3px;
  background-color: #fff;
  overflow: hidden;

  &.html {
    height: auto;
    flex-direction: column;
    padding: 0;
    border: none;
    border-radius: 0;
  }

  & > *:first-child {
    margin-right: 5px;
  }

  & > span,
  &t > i,
  & > mat-icon {
    margin-top: auto;
    margin-bottom: auto;
  }

  & > span.not-default {
    color: var(--accent);
  }

  & > .ql-container {
    border: none;
  }

  & > .ql-toolbar {
    border-top: none;
    border-left: none;
    border-top: none;
    background: #ffff;
  }

  &.multi-line {
    height: 100px;
    display: block;
    padding: 5px;

    & > .input {
      height: inherit;
      max-height: unset;
    }
  }

  &.inline {
    border: none;
  }

  &:focus,
  &:hover {
    // color: var(--primary);
    background-color: #ffff !important;
    // background: inherit;
    // outline: none;
  }

  & > * {
    @include middle();
  }

  & > select,
  & > .input,
  & > input {
    background: inherit;
    border: none;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    // width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    max-height: 30px;
    overflow-y: auto;
  }
}

.disabled .oa-input,
.oa-input.disabled,
.oa-input .disabled {
  font: var(--disabled);
}

input:focus,
input.selected,
.input:focus,
.input.selected {
  color: var(--primary);
  background-color: #ffff !important;
  background: inherit;
  outline: none;
}

input:hover,
.input:hover {
  // background-color: #ffff;
  background: inherit;
  // border-radius: 5px;
}

.oa-textarea {
  display: inline-block;
  width: 100%;
  border: 1px solid var(--disabledBackground);
  border-radius: 4px;
  background-color: #fff;

  &.scrollable {
    max-height: 300px;
    overflow: auto;
  }

  &:focus-visible {
    border: 1px solid var(--disabledBackground);
  }
}

.disabled .oa-textarea,
.oa-textarea.disabled,
.oa-textarea .disabled {
  font: var(--disabled);
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #c2c2c2 !important;
}

.oa-textarea > .textarea,
.oa-textarea > textarea {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  background: inherit;
  border: none;
  width: 100%;
  overflow-y: scroll;
  padding: 10px;
  border-radius: 4px;
}

textarea:focus,
textarea.selected,
.textarea:focus,
.textarea.selected {
  color: var(--primary);
  background-color: #ffff !important;
  outline: none;
}

textarea:hover,
.textarea:hover {
  background-color: #ffff !important;
  border-radius: 5px;
}

input[type="number"] {
  text-align: right;
}

.form-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  input.mat-input-element {
    height: 30px;
    padding-left: 10px;
    text-align: left;
    border: 1px solid var(--disabledBackground);
    border-radius: 4px;
    background-color: #fff;
  }

  .mat-hint {
    color: var(--disabled);
    font-size: small;
    text-align: right;
  }

  mat-label.error,
  mat-hint.error {
    color: var(--warn) !important;
  }

  & > * {
    width: 100%;

    & > mat-form-field {
      width: inherit;
    }
  }

  .footer {
    justify-content: center;
  }
}

.error {
  padding: 10px;
  color: var(--warn) !important;
  font-size: smaller;
}

label span.required,
.mandatory,
.required:after {
  content: " *";
  color: red;
}

a {
  color: var(--primary);
  cursor: pointer;
}

.file-card {
  background: #fff;
  max-width: 190px;
  min-width: 190px;
  max-height: 190px;
  min-height: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px;
  border: 1px solid #ffff;
  margin: 5px 24px 5px 15px;

  &.active {
    cursor: pointer;
  }

  & > .content > * {
    margin: 0px auto;
  }

  & > .summary {
    border-top: 1px solid #ffff;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;

    h5 {
      color: var(--primary);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: capitalize;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

/* widget start */

.widgets {
  &.icon-navs {
    display: block;
    flex-wrap: unset;
    justify-content: unset;
    align-items: unset;
    flex-direction: unset;
    margin: 20px;
  }

  & > .widget-row-title-wizard {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1rem;
    color: var(--disabled);
    position: relative;
    bottom: -50px;
    left: 12px;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(450deg);
    transform: rotate(450deg);
  }

  & > .widget-row-period {
    padding-left: 15px;
  }
}

.widget {
  @include fill-remaining-space();
}

.page-stats,
.widget-card {
  padding: 15px;
  margin: 10px 0px 0px 0px;
  background: white;
  box-shadow: 0 0px 40px -6px rgba(0, 0, 0, 0.2),
    0 1px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

  & > .widget-header {
    font-weight: 600;
    height: 30px;
  }

  & > .widget-title {
    border-bottom: 1px solid var(--disabledBackground);
  }

  & > .widget-body {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    min-height: 80px;
  }
}

.widget-background {
  padding: 15px;
  margin: 10px 0px 0px 0px;
  background: white;
  box-shadow: 0 0px 40px -6px rgba(0, 0, 0, 0.2),
    0 1px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  height: 250px;
  overflow: auto !important;

  & > .widget-header {
    font-weight: 600;
    height: 20px;
  }
}

.widget-table {
  text-align: left;

  & > thead > tr {
    height: 30px;
  }

  tbody {
    display: block;
    height: inherit;
    overflow: auto;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .summary {
    font-weight: bold;

    & > .clickable {
      @include badge();
    }
  }
}

.common-filters {
  width: 200px;
  margin-right: 5px;
  margin-left: calc(100% - 205px);
}

.widget-grid {
  .grid-card {
    text-align: center;
    background: white;
    height: 115px;
    width: 130px;
    padding-top: 10px;
    box-shadow: 0px 3px 6px #00000029;
    margin: 5px 10px 5px 0px;
  }

  .grid-value {
    font-size: 30px;
    color: var(--primary);
    font-weight: 600;
  }

  .grid-title {
    font-weight: 600;
    margin-top: 10px;
    color: #444444;
    border-top: 2px dashed #ddd;
    padding: 5px;
  }
}

.widget-grid-merged {
  // flex-wrap: wrap;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;
  text-align: center;
  overflow: hidden;

  .grid-card {
    text-align: center;
    background: white;
    margin-top: 10px;
    border-left: 2px dashed #ddd;
    margin-left: -2px;
    height: 80px;
    padding: 0 2px 0 2px;
  }

  .grid-value {
    font-size: 22px;
    color: var(--primary);
    font-weight: 600;
  }

  .grid-title {
    color: #000000;
    padding-top: 5px;
    font-size: 12px;
    text-align: center;
  }
}

.clickable-merged {
  color: var(--accent);
  cursor: pointer;
  font-size: 22px;
  font-weight: 600;
}

.widget-row {
  .field {
    text-align: center;
    width: 100px;

    & > .value {
      font-weight: 100;
      font-size: x-small;
      color: var(--accent);
    }

    & > .title {
      font-weight: 100;
      font-size: x-small;
      color: var(--disabled);
    }
  }
}

/* widget end */

core-page-divs {
  .mat-tab-group {
    min-height: 100px;
    margin-bottom: 10px;

    .mat-tab-label-container {
      background-color: #ffffff;
    }

    .mat-tab-body-content {
      padding: 10px;
      background-color: #ffffff;
    }

    // .mat-tab-body-content .widget {
    //   width: 100%;
    // }

    // .mat-tab-body-content .widget-title {
    //   display: none;
    // }

    .mat-tab-body-content > div > .title {
      display: none;
    }
  }
}

.oa {
  &.l-1,
  .l-1 {
    color: var(--error);
  }

  &.l-2,
  .l-2 {
    color: var(--warn);
  }

  &.l-3,
  .l-3 {
    color: var(--default);
  }

  &.l-4,
  .l-4 {
    color: var(--success);
  }
}

/* oa-theme:end */

/* icons:start */
.oa-icon,
i {
  height: 10px;
  width: 10px;
  background-size: cover;
  display: inline-block;
  margin-right: 3px;

  &.sm {
    height: 10px;
    width: 10px;

    &.x {
      height: 5px;
      width: 5px;
    }

    &.xx {
      height: 15px;
      width: 15px;
    }
  }

  &.md {
    height: 20px;
    width: 20px;

    &.x {
      height: 30px;
      width: 30px;
    }

    &.xx {
      height: 40px;
      width: 40px;
    }

    &.xxx {
      height: 50px;
      width: 50px;
    }
  }

  &.lg {
    height: 100px;
    width: 100px;

    &.x {
      height: 150px;
      width: 150px;
    }

    &.xx {
      height: 200px;
      width: 200px;
    }

    &.xxx-lg {
      height: 250px;
      width: 250px;
    }
  }

  &.x-sm {
    height: 5px;
    width: 5px;
  }

  &.xx-sm {
    height: 15px;
    width: 15px;
  }

  &.x-md {
    height: 30px;
    width: 30px;
  }

  &.xx-md {
    height: 40px;
    width: 40px;
  }

  &.xxx-md {
    height: 50px;
    width: 50px;
  }

  &.lg {
    height: 100px;
    width: 100px;
  }

  &.x-lg {
    height: 150px;
    width: 150px;
  }

  &.xx-lg {
    height: 200px;
    width: 200px;
  }

  &.xxx-lg {
    height: 250px;
    width: 250px;
  }
}

mat-icon {
  &.oa {
    height: unset;
    width: unset;
  }

  &.sm {
    font-size: small;

    &.x {
      font-size: x-small;
    }

    &.xx {
      font-size: xx-small;
    }
  }

  &.md {
    font-size: large;
  }

  &.x-lg {
    font-size: x-large;
  }

  &.xx-lg {
    font-size: xx-large;
  }

  &.lg {
    font-size: large;

    &.x {
      font-size: x-large;
    }

    &.xx {
      font-size: xx-large;
    }
  }
}

/* icons: start */

i.sprint {
  background-image: url("assets/icons/sprint.png");
}

i.release {
  background-image: url("assets/icons/release.png");
}

i.email {
  background-image: url("assets/icons/envelope.svg");
}

i.eye {
  background-image: url("assets/icons/eye.svg");
}

i.lock {
  background-image: url("assets/icons/lock.svg");
}

i.file-html {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/file-html.svg");
}

i.file-link {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/file-link.svg");
}

i.file-html.active,
.active i.file-html {
  background-image: url("https://openage.github.io/themes/openage/icons/red/file-html.svg");
}

i.file-link.active,
.active i.file-link {
  background-image: url("https://openage.github.io/themes/openage/icons/red/file-link.svg");
}

i.link.active,
.active i.link {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/dashboard/foreign.svg");
}

i.error-coming-soon {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/error/coming-soon.svg");
}

i.picture-upload {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/profile/documents/add-logo.svg");
}

.md.picture-upload {
  position: absolute;
  margin-left: 32px;
  margin-top: -32px;
}

i.file-add {
  background-image: url("assets/icons/add-file.svg");
}

i.file-upload {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/profile/documents/add-doc.svg");
}

i.drag-and-drop {
  background-image: url("https://openage.github.io/themes/kredible/v1/icons/system-admin/drag-and-drop.svg");
}

i.status-active {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/profile/documents/verified.svg");
}

i.status-submitted {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/profile/documents/submitted.svg");
}

i.status-canceled {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/profile/documents/rejected.svg");
}

i.no-notification {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/dashboard/no-notification.svg");
}

i.no-task {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/dashboard/no-task.svg");
}

i.message {
  &.enjoy {
    background-image: url("assets/images/messages/enjoy.png");
  }
}

i.error-no-data {
  background-image: url("assets/themes/checklist/images/notF.png");
}

i.no-data {
  background-image: url("assets/themes/checklist/images/notF.png");
}

i.error-wip {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/error/work-in-progress.svg");
}

i.file-add {
  background-image: url("assets/icons/add-file.svg");
}

i.file-drop {
  background-image: url("https://openage.github.io/themes/kredible/v1/icons/system-admin/drag-and-drop.svg");
}

i.file-doc {
  background-image: url("https://openage.github.io/themes/kredible/v1/icons/workspace/doc-file.svg");
}

i.file-img {
  background-image: url("https://openage.github.io/themes/kredible/v1/icons/workspace/img-file.svg");
}

i.file-excel {
  background-image: url("https://openage.github.io/themes/kredible/v1/icons/workspace/xls-file.svg");
}

i.file-pdf {
  background-image: url("https://openage.github.io/themes/kredible/v1/icons/workspace/pdf-file.svg");
}

i.reports {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/reports/grey/agent-wise-inquiries-report.svg");
}

i.reports.active,
.active i.reports {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/reports/agent-wise-inquiries-report.svg");
}

i.reports.hover {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/reports/white/agent-wise-inquiries-report.svg");
}

i.port-sea {
  background-image: url("https://cdn2.iconfinder.com/data/icons/marine-port/512/port-marine-navy-06-512.png");
}

i.port-air {
  background-image: url("https://cdn1.iconfinder.com/data/icons/logistics-fourteen-black-and-white/128/air_shipping-plane-logistics-cargo-512.png");
}

i.port-icd {
  background-image: url("https://cdn0.iconfinder.com/data/icons/business-e-commerce-logistics-import-export/500/containerstack-512.png");
}

i.location-warehouse {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/warehouse.svg");
}

i.truck {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/truck.svg");
}

i.section-details {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/profile/basic.svg");
}

i.section-details.active,
.active i.section-details {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/profile/active/basic.svg");
}

i.section-administrative {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/profile/administrative.svg");
}

.active i.section-administrative,
i.section-administrative.active {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/profile/active/administrative.svg");
}

i.section-business {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/profile/business.svg");
}

i.section-business.active,
.active i.section-business {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/profile/active/business.svg");
}

i.section-security {
  background-image: url("https://openage.github.io/themes/default/v1/icons/actions/grey/security-inactive.svg");
}

i.section-security.active,
.active i.section-security {
  background-image: url("https://openage.github.io/themes/default/v1/icons/actions/red/security-active.svg");
}

i.document {
  background-image: url("https://openage.github.io/themes/default/v1/icons/actions/grey/document-inactive.svg");
}

i.document.active,
.active i.document {
  background-image: url("https://openage.github.io/themes/default/v1/icons/actions/red/document-active.svg");
}

i.section-account {
  background-image: url("https://openage.github.io/themes/default/v1/icons/actions/grey/accounts-inactive.svg");
}

i.section-account.active,
.active i.section-account {
  background-image: url("https://openage.github.io/themes/default/v1/icons/actions/red/accounts-active.svg");
}

i.cluster {
  background-image: url("https://openage.github.io/themes/default/v1/icons/actions/grey/cluster-inactive.svg");
}

i.cluster.active,
.active i.cluster {
  background-image: url("https://openage.github.io/themes/default/v1/icons/actions/red/cluster-active.svg");
}

i.members {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/profile/promotors.svg");
}

i.members.active,
.active i.members {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/profile/active/promotors.svg");
}

i.section-requests {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/profile/requests.svg");
}

i.section-requests.active,
.active i.section-requests {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/profile/active/requests.svg");
}

i.business-filled {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/orders.svg");
}

i.business-filled.active,
.active i.business-filled {
  background-image: url("https://openage.github.io/themes/openage/icons/red/orders.svg");
}

i.marker {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/tracking.svg");
}

i.marker.active,
.active i.marker {
  background-image: url("https://openage.github.io/themes/openage/icons/red/tracking.svg");
}

i.srp {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/new-search.svg");
}

i.srp.active,
.active i.srp {
  background-image: url("https://openage.github.io/themes/openage/icons/red/new-search.svg");
}

i.section-bank {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/profile/bank.svg");
}

i.section-bank.active,
.active i.section-bank {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/profile/active/bank.svg");
}

i.lead,
.hover i.lead,
i.lead:hover {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/reports/grey/inquiries.svg");
}

i.lead.active,
.active i.lead {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/reports/inquiries.svg");
}

i.home {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/home.svg");
}

i.home.active,
.active i.home {
  background-image: url("https://openage.github.io/themes/openage/icons/red/home.svg");
}

i.travelExplore {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/travel-explore.svg");
}

i.travelExplore.active,
.active i.travelExplore {
  background-image: url("https://openage.github.io/themes/openage/icons/red/travel-explore.svg");
}

i.business-filled {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/business-filled.svg");
}

i.business-filled.active,
.active i.business-filled {
  background-image: url("https://openage.github.io/themes/openage/icons/red/business-filled.svg");
}

i.marker {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/marker.svg");
}

i.marker.active,
.active i.marker {
  background-image: url("https://openage.github.io/themes/openage/icons/red/marker.svg");
}

i.settings {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/settings.svg");
}

i.settings.active,
.active i.settings {
  background-image: url("https://openage.github.io/themes/openage/icons/red/settings.svg");
}

i.profile {
  background-image: url("https://openage.github.io//themes/kredible/v1/icons/organisational-setup/profile.svg");
}

i.profile.hover {
  background-image: url("https://openage.github.io/themes/kredible/v1/icons/organisational-setup/active/profile.svg");
}

i.profile.active,
.active i.profile {
  background-image: url("https://openage.github.io//themes/kredible/v1/icons/organisational-setup/profile.svg");
}

i.workspace {
  background-image: url("https://openage.github.io/themes/kredible/v1/icons/nav/workspace.svg");
}

i.workspace.active,
.active i.workspace {
  background-image: url("https://openage.github.io/themes/kredible/v1/icons/nav/active/workspace.svg");
}

i.todo {
  background-image: url("https://openage.github.io/themes/kredible/v1/icons/dashboard/to-do-list.svg");
}

i.todo.active,
.active i.todo {
  background-image: url("https://openage.github.io/themes/kredible/v1/icons/dashboard/active/to-do-list.svg");
}

i.inbox {
  background-image: url("https://openage.github.io/themes/kredible/v1/icons/dashboard/inbox.svg");
}

i.inbox.active,
.active i.inbox {
  background-image: url("https://openage.github.io/themes/kredible/v1/icons/dashboard/active/inbox.svg");
}

i.master {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/reports/grey/master.svg");
}

i.master.active,
.active i.master {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/reports/master.svg");
}

i.order {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/reports/grey/enquiry-flow-report.svg");
}

i.order.active,
.active i.order {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/reports/enquiry-flow-report.svg");
}

i.inbox {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/inbox.svg");
}

i.ticket {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/invoices.svg");
}

i.ticket.active,
.active i.ticket {
  background-image: url("https://openage.github.io/themes/openage/icons/red/invoices.svg");
}

i.inbox.active,
.active i.inbox {
  background-image: url("https://openage.github.io/themes/openage/icons/red/inbox.svg");
}

i.team {
  background-image: url("https://openage.github.io/themes/kredible/v1/icons/nav/team.svg");
}

i.team.active,
.active i.team {
  background-image: url("https://openage.github.io/themes/kredible/v1/icons/nav/active/team.svg");
}

i.quote {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/reports/grey/quote-flow-report.svg");
}

i.quote.active,
.active i.quote {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/reports/quote-flow-report.svg");
}

i {
  &.workflow-epic {
    background-image: url("https://openage.github.io/themes/default/v1/icons/workflows/epic.png");
  }

  &.workflow-story {
    background-image: url("https://openage.github.io/themes/default/v1/icons/workflows/story.png");
  }

  &.workflow-technical-story {
    background-image: url("https://cdn-icons-png.flaticon.com/512/2620/2620969.png");
  }

  &.workflow-task {
    background-image: url("https://openage.github.io/themes/default/v1/icons/workflows/task.png");
  }

  &.workflow-test-case {
    background-image: url("https://openage.github.io/themes/default/v1/icons/workflows/test-case.png");
  }

  &.workflow-test-plan {
    background-image: url("https://www.svgrepo.com/show/168339/test.svg");
  }

  &.workflow-defect {
    background-image: url("https://openage.github.io/themes/default/v1/icons/workflows/defect.png");
  }

  &.workflow-ticket {
    background-image: url("https://openage.github.io/themes/default/v1/icons/workflows/ticket.png");
  }

  &.workflow-suggestion {
    background-image: url("https://openage.github.io/themes/default/v1/icons/workflows/suggestion.png");
  }

  &.workflow-bau {
    background-image: url("https://openage.github.io/themes/default/v1/icons/workflows/bau.png");
  }

  &.workflow-risk {
    background-image: url("https://openage.github.io/themes/default/v1/icons/workflows/risk.png");
  }

  &.workflow-releases {
    background-image: url("./assets/icons/release.svg");
  }

  &.num-0 {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/grey/svg/zero.svg");
  }

  &.num-1 {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/grey/svg/one.svg");
  }

  &.num-2 {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/grey/svg/two.svg");
  }

  &.num-3 {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/grey/svg/three.svg");
  }

  &.num-4 {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/grey/svg/four.svg");
  }

  &.num-5 {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/grey/svg/five.svg");
  }

  &.num-6 {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/grey/svg/six.svg");
  }

  &.num-7 {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/grey/svg/seven.svg");
  }

  &.num-8 {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/grey/svg/eight.svg");
  }

  &.num-9 {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/grey/svg/nine.svg");
  }

  &.category-onboarding {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/grey/svg/nine.svg");
  }

  &.category-sales {
    background-image: url("https://openage.github.io/themes/openage/icons/grey/sales-dashboard.svg");
  }

  &.category-pricing {
    background-image: url("https://openage.github.io/themes/openage/icons/grey/pricing-dashboard.svg");
  }

  &.category-operations {
    background-image: url("https://openage.github.io/themes/openage/icons/grey/operation-dashboard.svg");
  }

  &.category-finance {
    background-image: url("https://openage.github.io/themes/openage/icons/grey/finance-dashboard.svg");
  }

  &.category-vendor {
    background-image: url("./assets/icons/vendor-grey.svg");
  }

  &.category-customer {
    background-image: url("https://openage.github.io/themes/yatra/v1/icons/reports/grey/customers.svg");
  }

  &.category-agent {
    background-image: url("https://openage.github.io/themes/yatra/v1/icons/reports/grey/agent-wise-inquiries-report.svg");
  }

  &.category-system {
    background-image: url("https://openage.github.io/themes/openage/icons/grey/system-dashboard.svg");
  }

  &.category-framework {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/grey/svg/nine.svg");
  }

  &.category-marketing {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/grey/svg/nine.svg");
  }

  &.category-support {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/grey/svg/nine.svg");
  }

  &.category-dev-ops {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/grey/svg/nine.svg");
  }

  &.category-misclaneous {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/grey/svg/nine.svg");
  }

  &.category-add {
    background-image: url("https://cdn.iconscout.com/icon/premium/png-256-thumb/add-category-2476304-2084863.png");
  }
}

.active i,
i.active {
  &.num-0 {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/blue/svg/zero.svg");
  }

  &.num-1 {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/blue/svg/one.svg");
  }

  &.num-2 {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/blue/svg/two.svg");
  }

  &.num-3 {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/blue/svg/three.svg");
  }

  &.num-4 {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/blue/svg/four.svg");
  }

  &.num-5 {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/blue/svg/five.svg");
  }

  &.num-6 {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/blue/svg/six.svg");
  }

  &.num-7 {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/blue/svg/seven.svg");
  }

  &.num-8 {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/blue/svg/eight.svg");
  }

  &.num-9 {
    background-image: url("https://openage.github.io/themes/default/v1/icons/numbers/blue/svg/nine.svg");
  }
}

i.phone {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/main-home/grey/phone-icon.svg");

  &.white {
    background-image: url("https://openage.github.io/themes/yatra/v1/icons/main-home/white/phone-icon.svg");
  }
}

i.debug {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/log/debug.svg");
}

i.info {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/log/info.svg");
}

i.warn {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/log/warn.svg");
}

i.error {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/log/error.svg");
}

i.phone.active,
.active i.phone {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/main-home/red/phone-icon.svg");
}

i.mail {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/main-home/grey/mail-icon.svg");

  &.white {
    background-image: url("https://openage.github.io/themes/yatra/v1/icons/main-home/white/mail-icon.svg");
  }
}

i.mail.active,
.active i.mail {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/main-home/red/mail-icon.svg");
}

i.ticket {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/reports/grey/tickets.svg");
}

i.ticket.active,
.active i.ticket {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/reports/tickets.svg");
}

i.particular {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/reports/grey/particular.svg");
}

i.task {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/action-item.svg");
}

i.task.active,
.active i.task {
  background-image: url("https://openage.github.io/themes/openage/icons/red/action-item.svg");
}

i.tasks {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/action-item.svg");
}

i.tasks.active,
.active i.tasks {
  background-image: url("https://openage.github.io/themes/openage/icons/red/action-item.svg");
}

i.particular.active,
.active i.particular {
  background-image: url("https://openage.github.io/themes/openage/icons/red/particular.svg");
}

i.customer {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/reports/grey/customers.svg");
}

i.customer.active,
.active i.customer {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/reports/customers.svg");
}

i.supplier {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/reports/grey/suppliers.svg");
}

i.supplier.active,
.active i.supplier {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/reports/suppliers.svg");
}

i.price {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/reports/grey/pricing.svg");
}

i.price.active,
.active i.price {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/reports/grey/pricing.svg");
}

i.task {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/task.svg");
}

i.task.active,
.active i.task {
  background-image: url("https://openage.github.io/themes/openage/icons/red/task.svg");
}

i.tasks {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/task.svg");
}

i.tasks.active,
.active i.tasks {
  background-image: url("https://openage.github.io/themes/openage/icons/red/task.svg");
}

i.messages {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/sidebar/messages.svg");
}

i.messages.active,
.active i.messages {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/sidebar/active/messages.svg");
}

i.message {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/sidebar/messages.svg");
}

i.message.active,
.active i.message {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/sidebar/active/messages.svg");
}

i.notes {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/sidebar/messages.svg");
}

i.notes.active,
.active i.notes {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/sidebar/active/messages.svg");
}

i.tags {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/sidebar/tag.svg");
}

i.tags.active,
.active i.tags {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/sidebar/active/tag.svg");
}

i.workflows {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/workflow.svg");
}

i.workflows.active,
.active i.workflows {
  background-image: url("https://openage.github.io/themes/openage/icons/red/workflow.svg");
}

i.products {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/product.svg");
}

i.products.active,
.active i.products {
  background-image: url("https://openage.github.io/themes/openage/icons/red/product.svg");
}

i.templates {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/template-editor.svg");
}

i.templates.active,
.active i.templates {
  background-image: url("https://openage.github.io/themes/openage/icons/red/template-editor.svg");
}

i.activities {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/sidebar/timeline.svg");
}

i.activities.active,
.active i.activities {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/sidebar/active/timeline.svg");
}

i.info {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/sidebar/info.svg");
}

i.info.active,
.active i.info {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/sidebar/active/info.svg");
}

i.help {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/sidebar/help.svg");
}

i.help.active,
.active i.help {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/sidebar/active/help.svg");
}

i.members {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/sidebar/members.svg");
}

i.members.active,
.active i.members {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/sidebar/active/members.svg");
}

i.my-dashboard {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/my-dashboard.svg");
}

i.my-dashboard.active,
.active i.my-dashboard {
  background-image: url("https://openage.github.io/themes/openage/icons/red/my-dashboard.svg");
}

i.finance-dashboard {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/finance-dashboard.svg");
}

i.finance-dashboard.active,
.active i.finance-dashboard {
  background-image: url("https://openage.github.io/themes/openage/icons/red/finance-dashboard.svg");
}

i.sales-dashboard {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/sales-dashboard.svg");
}

i.sales-dashboard.active,
.active i.sales-dashboard {
  background-image: url("https://openage.github.io/themes/openage/icons/red/sales-dashboard.svg");
}

i.operation-dashboard {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/operation-dashboard.svg");
}

i.operation-dashboard.active,
.active i.operation-dashboard {
  background-image: url("https://openage.github.io/themes/openage/icons/red/operation-dashboard.svg");
}

i.human-dashboard {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/human-resource-dashboard.svg");
}

i.human-dashboard.active,
.active i.human-dashboard {
  background-image: url("https://openage.github.io/themes/openage/icons/red/human-resource-dashboard.svg");
}

i.system-dashboard {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/system-dashboard.svg");
}

i.system-dashboard.active,
.active i.system-dashboard {
  background-image: url("https://openage.github.io/themes/openage/icons/red/system-dashboard.svg");
}

i.pricing-dashboard {
  background-image: url("https://openage.github.io/themes/openage/icons/grey/pricing-dashboard.svg");
}

i.pricing-dashboard.active,
.active i.pricing-dashboard {
  background-image: url("https://openage.github.io/themes/openage/icons/red/pricing-dashboard.svg");
}

i.drag-grab {
  opacity: 0;
  background-image: url("./assets/icons/drag-indicator.svg");
}

i.switch {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/landing/switch.svg");
}

i.log-info {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/log/info.svg");
}

i.log-debug {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/log/debug.svg");
}

i.log-error {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/log/error.svg");
}

i.log-warn {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/log/warn.svg");
}

i.log-silly {
  background-image: url("https://openage.github.io/themes/yatra/v1/icons/log/silly.svg");
}

i.slack {
  background-image: url("./assets/icons/slack.png");
}

i.email {
  background-image: url("./assets/icons/email.png");
}

i.whatsapp {
  background-image: url("./assets/icons/whatsapp.png");
}

i.copy {
  background-image: url("./assets/icons/copy.png");
}

/* icons:end */

.center {
  text-align: center;
}

.avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-size: cover;
  border: 1px solid var(--disabled);
}

.avatar.default {
  background-image: url("assets/images/users/default.png");
}

.avatar.customer {
  background-image: url("assets/images/customers/default.png");
}

/* icons:end */

.center {
  text-align: center;
}

.logo {
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;

  &.default {
    background-image: url("assets/images/logo.png");
  }

  &.lg {
    height: 85px;
    width: 200px;
    margin-left: 0px;
  }
}

.avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-size: cover;
  border: 1px solid var(--disabled);
}

.avatar.lg {
  height: 100px;
  width: 100px;
}

.profile-image-editor {
  text-align: center;
  // margin-top: 40px;
}

.profile-image-editor img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0px auto;
}

.profile-image-editor .default {
  width: 115px;
  height: 115px;
  padding-top: 10px;
  border-radius: 50%;
  cursor: pointer;
  background: #f1f1f1;
  color: #bfbfbf;
  font-size: 14px;
  margin: 0px auto;
}

.profile-image-editor > .oa {
  position: absolute;
  margin-top: -30px;
  margin-left: 26px;
}

.close-button {
  float: right;
  width: 0 !important;
  right: 15px;
  top: -18px;
}

mat-label.error,
mat-hint.error {
  color: var(--warn) !important;
}

.json .string {
  color: green;
}

.json .number {
  color: darkorange;
}

.json .boolean {
  color: blue;
}

.json .null {
  color: magenta;
}

.json .key {
  color: red;
}

.selected-list .c-list .c-token {
  margin-top: -5px !important;
}

.uploadDialog .mat-dialog-container {
  width: "500px";
  height: "auto";
  padding: 0px;
}

.mat-mini-fab .mat-button-wrapper {
  padding: 0px 0 !important;
  line-height: 0px !important;
}

.btn {
  &.alt {
    background: linear-gradient(
      to right,
      #0cf 0%,
      #0cf 25%,
      #fff 26%,
      #fff 50%,
      #0cf 51%,
      #0cf 75%,
      #fff 76%
    );
  }

  &.half {
    background: linear-gradient(to right, #0cf 0%, #0cf 50%, #fff 51%);
  }

  &.full {
    background: #0cf;
  }

  &.off {
    background: #fff;
  }
}

.btn-toggle {
  margin-left: 10px;
  outline: none;
}

.form-control {
  &.btn-group {
    padding: 3px;
    height: auto;

    & > .btn {
      border-radius: 5px;
      margin-left: 1px;
      box-shadow: 0 0px 3px rgba(0, 0, 0, 0.15);
      outline: none;

      &.btn-primary {
        color: var(--primary);
        background: #fff;
      }
    }
  }
}

.week.btn-group > .btn {
  width: 13.7%;
}

.btn-group > .btn.btn-circle:first-child:not(:last-child):not(.dropdown-toggle),
.btn-group
  > .btn.btn-circle:not(:first-child):not(:last-child):not(.dropdown-toggle),
.form-control.btn-group > .btn.btn-circle {
  border-radius: 20px;
  width: 26px;
  height: 26px;
  margin: 2px;
  border: 1px solid;
}

.help .sub-text,
.form-group .sub-text {
  position: relative;
  top: -15px;
}

.mat-menu-item:focus,
.mat-icon-button:focus,
.mat-button-toggle-button:focus {
  outline: none;
}

.mat-menu-item > i {
  // vertical-align: middle;
  fill: currentColor;
  height: 24px;
  width: 24px;
  // margin-right: 16px;
  color: var(--secondary);
}

section {
  border: solid 1px #ffff;
  border-radius: 5px;
  margin: 10px 0px 10px;
  padding: 0px 10px 0px;

  & > label {
    position: relative;
    top: -10px;
    padding: 0 10px 0 10px;
    background: #ffffff;
  }
}

.tabs {
  &.side {
    margin-top: 5px;
    padding: 0px;
    height: fit-content;

    & > * {
      background: #fff;
      color: #212121;
      height: 47px;
      padding: 14px;
      padding-left: 15px;
      box-shadow: 0px 3px 6px #0000000f;
      margin-bottom: 2px;
      cursor: pointer;

      &.active {
        border: 2px solid var(--primary);
        color: var(--primary);
      }
    }
  }

  &.horizontal {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    min-height: 40px;
    overflow-x: overlay;
    // border-bottom: 2px solid var(--defaultBackdrop);

    // &:hover {
    //   overflow-x: overlay;
    // }

    &.tabs-container::-webkit-scrollbar {
      display: none;
    }

    &.with-actions {
      & > * {
        -webkit-box-flex: unset;
        -ms-flex-positive: unset;
        flex-grow: unset;
        min-width: 125px;
      }

      .spacer {
        @include fill-remaining-space();
      }

      .actions {
        min-width: unset;

        & > * {
          margin-left: 5px;
        }
      }
    }

    &.tabs-container {
      & > * {
        -webkit-box-flex: unset;
        -ms-flex-positive: unset;
        flex-grow: unset;
        color: var(--disabled);
      }

      .without-stat {
        min-width: 125px;
      }

      .spacer {
        @include fill-remaining-space();
      }
    }

    & > * {
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      padding: 5px;
      // border-bottom: 1px solid var(--defaultBackdrop);

      &.active {
        border-bottom: 2px solid var(--primary);
      }
    }
  }

  & > * {
    cursor: pointer;

    &.active {
      color: var(--primary);

      & > .label,
      & > label {
        color: var(--primary);
      }
    }

    &.with-stat {
      & > .label,
      & > label {
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    & > i {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  button {
    @include inline-control();
  }
}

.cards {
  & > * {
    margin: 5px 0;
    padding: 5px;
    min-height: 40px;

    &:first-child {
      margin-top: 0px;
    }
  }
}

.toggler {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  height: 30px;
}

.toggler > * {
  border: 1px solid var(--default);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #ffffff;
  color: var(--default);
  border-radius: 5px;
  margin-right: 5px;
  text-align: center;
  height: max-content;
  cursor: pointer;
  width: max-content;
}

.toggler.sm {
  height: 20px;
}

.toggler.sm > * {
  border-radius: 2px;
  padding: 1px 3px;
  font-size: small;
}

.toggler > *.active {
  border-color: var(--primary);
  color: var(--primary);
}

.toggler > *.is-processing {
  border: 1px dotted var(--primary);
}

.toggler.readonly > *.active {
  border-color: var(--primaryBackground);
  background-color: var(--primaryBackground);
}

.badge,
.pill-box {
  border: 1px solid var(--disabledBackground);
  background: white;
  width: fit-content;

  font-size: smaller;
  height: 20px;
  display: flex;
  flex-direction: row;

  &.primary {
    border: 1px solid var(--primary);

    & > span.value {
      color: #fff;
      background: var(--primary);
    }
  }

  &.accent {
    border: 1px solid var(--accent);

    & > span.value {
      color: #fff;
      background: var(--accent);
    }
  }

  &.default {
    border: 1px solid var(--default);

    & > span.value {
      color: #fff;
      background: var(--default);
    }
  }

  &.warn {
    border: 1px solid var(--warn);

    & > span.value {
      color: #fff;
      background: var(--warn);
    }
  }

  &.with-value {
    padding-right: 0px;
  }

  & > span,
  & > i,
  & > mat-icon {
    margin-top: auto;
    margin-bottom: auto;
    font-size: unset;
    height: unset;
    width: unset;
    line-height: inherit;
  }

  & > span.value {
    color: #fff;
    background: var(--defaultBackdrop);
    font-weight: 100;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: 2px;
    margin-left: 2px;

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      padding-left: 5px;
      margin-left: 5px;
    }

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      padding-right: 5px;
      margin-right: 5px;
    }

    &:only-child {
      padding-right: 10px;
      padding-left: 10px;
      margin-right: 2px;
      margin-left: 2px;
    }
  }

  & > mat-icon:last-child {
    margin-left: 5px;
    right: -5px;
    position: relative;
  }
}

.badge {
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 3px;
}

.pill-box {
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
}

.form-field {
  border-bottom: solid 1px var(--disabled);
  margin: 0px 5px 10px 5px;

  & > label {
    font-size: x-small;
    color: var(--disabled);
  }

  & > .toggler {
    & > * {
      border: 1px solid var(--disabled);
      padding: 2px 10px;
      margin: 5px 2px;
      color: var(--disabled);
      border-radius: 5px;
      font-size: small;
    }

    &.active {
      border: 1px solid var(--primary);
      background-color: #ffffff;
      color: var(--primary);
    }
  }
}

.setOverflow .mat-dialog-container {
  overflow: hidden;
}

.pointer,
.cursorPointer {
  cursor: pointer;
}

.add-row {
  cursor: pointer;
  color: var(--accent) !important;
  font-size: smaller;
  padding-left: 10px;
  width: max-content;
}

// table {
//   width: 100%;
// }

div.table {
  width: 100%;

  &.mat-elevation-z2 {
    background-color: #ffffff;
  }

  & > .header {
    @include padded-horizontal-container();
    @include row-of-elements();

    color: var(--disabled);

    border-bottom: 1px solid #ffff;
    padding-bottom: 10px;
    padding-top: 10px;

    font-size: 12px;
    line-height: 17.1429px;

    text-align: left;

    &.with-background {
      background: #ffff;
      border-radius: 4px;
    }

    & > * {
      width: 100%;
    }
  }

  & > .data-row {
    & > * {
      width: 100%;
    }
  }

  & > .footer {
    font-weight: bold;
    color: var(--disabled);

    & > * {
      width: 100%;
    }
  }

  .currency,
  .number {
    text-align: right;
  }

  .text {
    text-align: left;
    // color:blue
  }

  .merged {
    text-align: center;
    border-bottom: 1px solid #ffff;
  }

  .add-row {
    color: var(--accent) !important;
    font-size: smaller;
    padding-left: 10px;
  }
}

.data-row {
  @include padded-horizontal-container();
  @include padded-vertical-container();
  @include row-of-elements();

  border-bottom: 1px solid #ffff;

  & > * {
    margin-right: 5px;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: none;
    margin-right: 0px;
  }

  & > .actions {
    @include row-of-elements();
    @include subtle();

    & > * {
      margin-right: 5px;
    }

    :first-child {
      margin-left: auto;
    }
  }

  button {
    @include inline-control();
  }

  .oa-input {
    @include inline-control();
    width: -webkit-fill-available;
    height: 30px;
    margin-right: 5px;
    margin-left: 0px;

    &.mat-select {
      padding-top: 5px;
    }
  }

  &.selected,
  &:hover {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  .data-row {
    padding: 0 0 3px 0px;
    border-bottom: none;
  }
}

// .mat-select {
//   height: 40px;
//   font-family: Poppins !important;
//   font-size: 14px;
//   padding-top: 10px;
//   padding-left: 8px;
//   border: 1px solid var(--disabledBackground);
//   border-radius: 4px;
//   background-color: #fff;
//   ;

//   &:focus,
//   &:hover {
//     background-color: #ffff !important;
//   }

//   &>.mat-select-arrow-wrapper {
//     padding-top: 12px;
//   }
// }

.mat-datepicker-toggle {
  position: absolute !important;
  margin-top: -22px !important;
  margin-left: -35px !important;
}

// .mat-form-field-wrapper {
//   padding-bottom: 0px !important;
// }

.mat-form-field-suffix {
  margin-left: -25px !important;
  top: -7px !important;

  & > img {
    width: 20px;
  }
}

.mat-form-field-infix {
  width: 100% !important;
}

.mat-list > button {
  margin-left: initial;
}

.no-connection {
  background-image: url("/assets/images/no-internet-image.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
}

.select-loading > span.mat-option-text {
  display: flex;
  justify-content: center;
}

.textAlignCenter {
  text-align: center;
}

.textDisabled {
  color: var(--disabled) !important;
  pointer-events: none;
  cursor: not-allowed;
}

//common classes for flex start

.flex {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-rev {
  flex-wrap: wrap-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.jc-start {
  justify-content: flex-start;
}

.jc-end {
  justify-content: flex-end;
}

.jc-center {
  justify-content: center !important;
}

.jc-around {
  justify-content: space-around;
}

.jc-between {
  justify-content: space-between;
}

.ai-start {
  align-items: flex-start;
}

.ai-end {
  align-items: flex-end;
}

.ai-center {
  align-items: center !important;
}

.ai-baseline {
  align-items: baseline;
}

.ai-stretch {
  align-items: stretch;
}

.ac-start {
  align-content: flex-start;
}

.ac-end {
  align-content: flex-end;
}

.ac-center {
  align-content: center;
}

.ac-between {
  align-content: space-between;
}

.ac-around {
  align-content: space-around;
}

.ac-stretch {
  align-content: stretch;
}

.g-05 {
  gap: 0.5em;
}

.g-1 {
  gap: 1em;
}

.g-2 {
  gap: 2em;
}

.g-3 {
  gap: 3em;
}

.g-4 {
  gap: 4em;
}

.g-5 {
  gap: 5em;
}

//common classes for flex end

//common padding

.p-1 {
  padding: 1em;
}

.p-2 {
  padding: 2em;
}

.custom-paginator-page {
  border-radius: 0.3em;
  padding: 0.3em 1em;
  margin: 0 0.5em;
  border: 1px solid lightgray;
}

.custom-paginator-page-enabled {
  background-color: white;
  box-shadow: none;
}
@media (max-height: 600px) {
  .custom-paginator-page {
    padding: 0.2em 0.8em;
    margin: 0 0.4em;
  }
}
.custom-paginator-page-disabled {
  background-color: var(--primary);
  color: white;
}

@media (max-width: 800px) {
  .app-container {
    padding-left: 5px;
    padding-right: 5px;
    // width: calc(100vw);
  }

  .flex-row {
    &.six > *,
    &.five > * {
      flex-basis: 30%;
    }

    &.four > * {
      flex-basis: 50%;
    }

    &.three > *,
    &.two > *,
    &.one > * {
      flex-basis: 100%;
    }
  }

  .flex-9-1 > :last-child,
  .flex-8-2 > :last-child,
  .flex-8-1 > :last-child,
  .flex-6-1 > :last-child,
  .flex-5-1 > :last-child,
  .flex-3-1 > :last-child {
    display: none;
  }

  .flex-1-9 > :first-child,
  .flex-2-8 > :first-child,
  .flex-1-8 > :first-child,
  .flex-1-6 > :first-child,
  .flex-1-5 > :first-child,
  .flex-1-3 > :first-child {
    display: none;
  }
}

@media (max-width: 600px) {
  .logo-container {
    height: 56px;
  }

  .flex-row {
    &.six > *,
    &.five > *,
    &.four > * {
      flex-basis: 100%;
    }
  }
}

@media only screen and (max-width: 568px) {
  .app-container {
    padding-left: 0px;
    padding-right: 0px;
    width: calc(100vw);
    margin-left: 0px;
    overflow: overlay;
  }

  .header-container {
    width: 100%;
    box-shadow: none;
    left: 0;
    right: 0;
    top: 0;
  }

  .main {
    padding-bottom: 55px;
    overflow: unset;
    padding-left: 0;
    padding-right: 0;

    .controls-row {
      flex-wrap: wrap;
    }
  }

  oa-date-picker {
    width: 100%;
  }

  .scrollable > * {
    overflow-y: unset;
    height: auto;
  }

  .controls-row {
    flex-wrap: wrap;
  }

  // .mat-select {
  //   margin-bottom: 15px;
  // }

  .addressSection section {
    border: 0;
    padding: 0;
  }

  label {
    margin-top: 10px;
  }

  .inputSection .mat-select {
    margin-bottom: 0px;
  }

  .pagination button.active {
    color: #fff;
  }

  .pagination {
    button {
      &.mat-flat-button {
        width: 15px;
        padding: 0 2px;
        margin-right: 3px;
      }
    }

    & > :nth-child(2) {
      width: auto !important;
    }

    & > :nth-last-child(2) {
      width: auto !important;
    }
  }

  .mat-option:focus:not(.mat-option-disabled),
  .mat-option:hover:not(.mat-option-disabled) {
    background: transparent !important;
  }

  .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
    background-color: #fff;
  }

  gateway-task-states {
    overflow-x: auto;
    padding-top: 10px;

    .stepper {
      overflow: hidden;
      width: 1000px;
      min-width: 400px;
    }
  }

  .logo {
    &.lg {
      height: 42px;
      width: 100px;
      margin-left: 0px;
    }
  }

  .stepper .hr-line {
    width: 7px;
    flex-grow: 0 !important;
  }

  mat-sidenav-content {
    .widget {
      position: static;
      overflow: unset;
      margin-right: 0px;

      & > div {
        height: 100%;
        overflow: unset;
      }
    }
  }

  mat-sidenav-content.mat-drawer-content {
    overflow: auto !important;
  }

  .file-card {
    margin: 5px 5px;
  }

  .main td .controls-row {
    flex-wrap: nowrap;
  }

  gateway-state-picker {
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;

    .toggler {
      overflow-x: auto;
      height: 41px;
      margin: 10px 0;

      span {
        min-width: auto !important;
      }
    }
  }

  tr th,
  tr td {
    padding-right: 10px !important;
  }

  .table {
    // margin-top: 10px;
  }

  .oa-input input {
    width: 100px;
  }

  gateway-task-list {
    .flex-1-1-1 > * {
      flex-basis: 100%;
    }

    .flex-1-1-1 {
      flex-wrap: wrap;
    }
  }

  page-task {
    .flex-2-1.scrollable {
      overflow: unset;
      flex-wrap: wrap;
    }

    .flex-2-1 > :first-child,
    .flex-2-1 > :last-child {
      flex-basis: 100%;
      width: 100% !important;
    }

    .flex-2-1 > :last-child {
      padding-bottom: 50px;
    }
  }

  gateway-time-log-list {
    display: block;
    width: 100%;
    overflow-x: auto;

    .table {
      width: 540px;
    }
  }

  .data-row.draggable.subtle {
    flex-wrap: wrap;
    justify-content: flex-end;

    oa-input {
      width: 78%;
    }

    oa-date-picker {
      width: 35px;
    }
  }

  .footer oa-date-picker {
    width: 35px;
  }
}

@media (max-width: 411px) {
  .logo-container {
    display: none;
  }

  .flex-row {
    & > .col {
      flex-basis: 100%;
    }

    & > .left {
      order: 1;
      flex-basis: 100%;
    }

    & > .middle {
      order: 3;
      flex-basis: 100%;
    }

    & > .right {
      order: 2;
      flex-basis: 100%;
    }
  }

  .w-p {
    flex-wrap: wrap;

    & > :first-child {
      flex-basis: 100%;
    }

    & > :last-child {
      flex-basis: 100%;
    }
  }
}
